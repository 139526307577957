.timeline {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

@media (min-width: 960px) {
  .line::before {
    position: absolute;
    content: "";
    display: block;
    width: 1px;
    left: 50%;
    top: 0;
    bottom: 0;
    background-color: #9b9b9b;
    background-size: cover;
    height: 100%;
  }
  .timeline-description-p {
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

.timeline-meta-reverse {
  position: relative;
  width: 50%;
}

.timeline-date-reverse {
  font-size: 3vw;
  font-family: dancing script, cursive;
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  color: #9b9b9b;
  left: 6vw;
  right: auto;
  line-height: 65.568px;
}

.timeline-description-reverse {
  overflow: hidden;
  width: 50%;
}

.timeline-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.meta::before {
  position: absolute;
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background-color: #9b9b9b;
  top: 50%;
  left: 0;
}

.timeline-meta {
  position: relative;
  width: 50%;
}

.timeline-date {
  font-size: 3vw;
  font-family: dancing script, cursive;
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  color: #9b9b9b;
  right: 6vw;
  line-height: 65.568px;
}

.timeline-heart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  overflow: hidden;
}

.timeline-description {
  overflow: hidden;
  width: 50%;
}

.timeline-description-p {
  color: #4a4a4a;
  font-family: "fira sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.timeline-li-reverse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-direction: row-reverse;
  margin-top: 1em;
}

.story-section {
  width: 18vw;
  height: 18vw;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  filter: contrast(60%);
  transition: all ease 0.3s;
  margin-right: 0;
}

.story-section-reverse {
  width: 18vw;
  height: 18vw;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  filter: contrast(60%);
  transition: all ease 0.3s;
  margin-left: auto;
}

.story-section:hover,
.story-section-reverse:hover {
  -webkit-filter: contrast(100%);
  -moz-filter: contrast(100%);
  -ms-filter: contrast(100%);
  filter: contrast(100%);
}

.story-section-img1 {
  background-image: url(../../assets/img/before.jpg);
}

.story-section-img2 {
  background-image: url(../../assets/img/prewed.jpg);
}

.story-section-img3 {
  background-image: url(../../assets/img/reunited.jpg);
}

.story-section-img4 {
  background-image: url(../../assets/img/separated.jpg);
}

.story-section-img5 {
  background-image: url(../../assets/img/ring14.jpg);
}

.story-section-img6 {
  background-image: url(../../assets/img/engagement5.jpg);
}

@media (max-width: 960px) {
  ul.timeline > li + li {
    margin-top: 2em;
  }
  li.timeline-li,
  li.timeline-li-reverse {
    flex-wrap: wrap;
  }
  div.timeline-meta,
  div.timeline-meta-reverse {
    width: 100%;
  }
  .meta::before {
    width: calc(100% - 1em);
    padding-left: 1em;
  }
  div.timeline-description,
  div.timeline-description-reverse {
    width: 100%;
  }
  div.timeline-heart {
    display: none;
  }
}

@media only screen and (max-width: 960px) {
  .timeline-date,
  .timeline-date-reverse {
    font-size: 1.5em;
  }
}
