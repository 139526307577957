.parallax-styles {
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 20px;
}

.parallax-styles-ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.heart {
  font-size: 3rem;
  fill: #e86c6c;
}

.image-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(150, 147, 147, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flower-white::before {
  position: absolute;
  content: "";
  display: block;
  width: 43.2vmin;
  height: 43.2vmin;
  background-image: url(../../assets/img/flowers-white.svg);
  background-position: center center;
  background-size: cover;
  left: -24.3vmin;
  top: -1.44rem;
}
.flower-white::after {
  position: absolute;
  content: "";
  display: block;
  width: 43.2vmin;
  height: 43.2vmin;
  background-image: url(../../assets/img/flowers-white.svg);
  background-position: center center;
  background-size: cover;
  right: -24.3vmin;
  bottom: -1.44rem;
}

@media (max-width: 600px) {
  div.parallax-styles {
    max-width: 260px;
  }
}
