
.event-list {
  padding: 0;
  list-style: none;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.event-list-li {
  display: flex;
  align-items: stretch;
}

.event-list-li-2 {
  margin-top: 3em;
  padding-top: 3em;
  box-shadow: 0 -1px 0 rgb(155 155 155 / 30%);
  width: 100%;
}

.event-thumbnail {
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  margin-bottom: 1em;
}

.event-thumbnail-img1 {
  background-image: url(../../assets/img/sample.jpg);
}

.event-thumbnail-img2 {
  background-image: url(../../assets/img/sample37.jpg);
}

.event-details {
  overflow: hidden;
}

.event-details-title {
  font-family: dancing script, cursive;
  font-size: 30.06px;
  margin: 0;
  color: #4a4a4a;
  font-weight: 700;
  line-height: 33.066px;
}

.event-info-ul {
  padding: 0;
  list-style: none;
  margin: 1em 0;
  font-size: 16.2px;
  font-weight: 700;
  line-height: 29.16px;
  color: #4a4a4a;
}

.event-info-span {
  display: inline-block;
  color: #e86c6c;
  width: 2em;
}

.event-details-description {
  max-width: 100%;
  color: #9b9b9b;
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  margin-bottom: 1em;
}

.event-section {
  margin: 2rem 0;
  padding: 2rem 1rem;
  overflow: hidden;
  position: relative;
  display: block;
}

.event-section-intro {
  width: 36em;
  max-width: 80%;
  margin: 1em auto 3em;
  text-align: center;
  font-family: "fira sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  color: #4a4a4a;
}

.event-section-intro-p {
  margin: 0 0 10px;
}

@media (min-width: 960px) {
  .MuiGrid-root.event-list {
    flex-grow: 0;
    max-width: 234px;
    flex-basis: 234px;
    margin-right: 3em;
  }
  .MuiGrid-root > .event-list-li-2 {
    width: calc(50% + 290px);
  }
  a.event-directions {
    margin-left: 1em;
  }
}

@media (max-width: 959px) {
  a.event-directions {
    margin-top: 1em;
  }
}
