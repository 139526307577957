.separator-carousel-container {
  height: 100%;
  width: 100%;
}

.separator-carousel-img {
  height: 50vh;
  background-size: cover;
  background-position: center center;
  filter: contrast(70%);
  transition: all ease 0.3s;
}

.separator-carousel-img:hover {
  -webkit-filter: contrast(100%);
  -moz-filter: contrast(100%);
  -ms-filter: contrast(100%);
  filter: contrast(100%);
}

@media (max-width: 960px) {
  .separator-carousel-img {
    height: 100vw;
  }
}

.carousel-img1 {
  background-image: url(../../assets/img/separator/separator-12.jpg);
}

.carousel-img2 {
  background-image: url(../../assets/img/separator/separator-13.jpg);
}

.carousel-img3 {
  background-image: url(../../assets/img/separator/separator-3.jpg);
}

.carousel-img4 {
  background-image: url(../../assets/img/separator/separator-4.jpg);
}

.carousel-img5 {
  background-image: url(../../assets/img/separator/separator-5.jpg);
}
