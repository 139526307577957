.comment-container {
  background-image: url(../../assets/img/seigaiha.png);
  background-repeat: repeat;
  padding: 2em;
}

.comment-field-container {
  width: 100%;
}

div.comment-list-container {
  margin-top: 32px;
  max-height: 502px;
  overflow: auto;
}

.comment-list-container::-webkit-scrollbar-track {
  background-color: #bdbdbd;
}

.comment-list-container::-webkit-scrollbar {
  width: 8px;
  background-color: #bdbdbd;
}

.comment-list-container::-webkit-scrollbar-thumb {
  background-color: #e86c6c;
}

.comment-list-loading-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(197, 194, 194, 0.1);
}
