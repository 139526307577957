html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.6;
}
body::-webkit-scrollbar-track {
  background-color: #bdbdbd;
}

body::-webkit-scrollbar {
  width: 8px;
  background-color: #bdbdbd;
}

body::-webkit-scrollbar-thumb {
  background-color: #e86c6c;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}
