.flower::before {
  position: absolute;
  content: "";
  display: block;
  width: 43.2vmin;
  height: 43.2vmin;
  background-image: url(../../assets/img/flowers.svg);
  background-position: center center;
  background-size: cover;
  opacity: 0.1;
  top: -4.8rem;
  left: -21.6vmin;
}
.flower::after {
  position: absolute;
  content: "";
  display: block;
  width: 43.2vmin;
  height: 43.2vmin;
  background-image: url(../../assets/img/flowers.svg);
  background-position: center center;
  background-size: cover;
  opacity: 0.1;
  top: -4.8rem;
  right: -21.6vmin;
}
.couple-intro {
  max-width: 80%;
  margin: 2em auto 3em;
  text-align: center;
}
.couple-intro-body {
  font-size: 18px;
  font-weight: 500;
  color: #4a4a4a;
  display: block;
  line-height: 28.8px;
  max-width: 600px;
  margin: 0 auto;
}
.couple-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}
.couple-and {
  font-size: 4em;
  font-family: "dancing script", cursive;
  color: #9b9b9b;
  display: flex;
  align-items: center;
  justify-content: center;
}
.couple-text {
  color: #4a4a4a;
  font-size: 1.8em;
  margin: 20px 0 0;
  line-height: 34.875px;
  text-align: center;
}
.couple-subtext {
  font-size: 22.5px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 1em;
  color: #4a4a4a;
}
.couple-description {
  color: #9b9b9b;
  max-width: 350px;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}
