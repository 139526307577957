.header {
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 40px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.header .header-carousel-container {
  position: absolute;
  height: 100%;
  width: 100%;
}

.carousel-img {
  height: 100vh;
  background-size: cover;
  background-position: center center;
}

.img1 {
  background-image: url(../../assets/img/carousel/1.jpg);
}

.img2 {
  background-image: url(../../assets/img/carousel/2.jpg);
}

.img3 {
  background-image: url(../../assets/img/carousel/3.jpg);
}

.img4 {
  background-image: url(../../assets/img/carousel/4.jpg);
}

.img5 {
  background-image: url(../../assets/img/carousel/5.jpg);
}

.header .header-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(97, 92, 92, 0.5);
}

.shape-decor {
  fill: white;
}

.shape-heart {
  fill: #e86c6c;
}

.title-greetings {
  font-family: "dancing script", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 6.75vmin;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
}

.title-name {
  display: inline;
}

.title-name .name {
  font-size: 6.75vmin;
  font-weight: 700;
}

.title-name .and {
  font-size: 6.75vmin;
  font-weight: 400;
  margin-left: 2vmin;
  margin-right: 2vmin;
}

.title-date {
  position: relative;
  font-weight: 400;
  font-size: 3.375vmin;
  margin: 5px 0;
}

.title-date > span {
  position: relative;
}

.title-date > span::before {
  position: absolute;
  content: '';
  display: block;
  top: 50%;
  right: 100%;
  margin-left: 2em;
  margin-right: 1em;
  height: 1px;
  width: 5em;
  background-color: #fff;
}

.title-date > span::after {
  position: absolute;
  content: '';
  display: block;
  top: 50%;
  left: 100%;
  margin-right: 2em;
  margin-left: 1em;
  height: 1px;
  width: 5em;
  background-color: #fff;
}

.announcement-wrapper {
  text-align: center;
  position: relative;
  z-index: 2;
}

.announcement {
  position: relative;
  margin-bottom: 20px;
}

.heart {
  margin-bottom: 10px;
}

.shape-mini-decor {
  fill: #777474;
}
