.qrcode-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.qrcode-text {
  font-size: 22.5px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 1em;
  color: #4d4949;
}
